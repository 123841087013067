<script>
import ApexCharts from 'apexcharts';

export default {
  name: 'ChartComponent',
  props: {
    chartOptions: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    // We dont use immediate: true because $refs is only available once component is mounted.
    chartOptions() {
      this.renderChart();
    },
  },
  created() {
    this.selector = `chart-${this.id}`;
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.updateOptions(this.chartOptions);
        } else {
          this.chart = new ApexCharts(this.$refs[`${this.selector}`], this.chartOptions);
          this.chart.render();
        }
      });
    },
  },
};
</script>
<template>
  <div
    :ref="`${selector}`"
    class="emobg-background-color-white"
  />
</template>

